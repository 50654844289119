import React from 'react'
import { Link, Page, PageProps } from 'gatsby'

import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline'
import ServiceDetails from '../components/ServiceDetails'
import Footer from '../components/Footer'
import Header from '../components/Header'

const AboutUs = (props: PageProps) => {
  return (
    <div className="bg-white">

      <Header path={props.path} />

      <main className="mt-16 mx-auto px-4 sm:mt-24 sm:px-6 lg:mt-32">
          
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                <svg
                  className="absolute top-12 left-full transform translate-x-32"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                </svg>
                <svg
                  className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                </svg>
                <svg
                  className="absolute bottom-12 left-full transform translate-x-32"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                </svg>
              </div>
            </div>
            <div className="relative">
              <div className="text-lg mx-auto">
                <h1>
                  <span className="block text-base text-center text-sekyee-blue font-semibold tracking-wide uppercase">
                    ABOUT Company
                  </span>
                  <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    Watch our video to learn more
                  </span>
                </h1>

                <div className="relative mt-16 mx-auto w-full rounded-lg shadow-lg lg:max-w-full">
                  <div className="mt-1 aspect-w-16 aspect-h-9">
                    <video 
                      controls controlsList="nodownload" 
                      src="https://sekyee-media.s3.eu-west-2.amazonaws.com/promo/sekyee-promo.mp4?autoplay=0#t=1"
                    >
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
    
      <Footer />
    </div>
  )
}

export default AboutUs